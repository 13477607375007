<template>
  <div class="four-o-four">
    <main id="four-o-four">
      <section class="four-o-four-section">
        <h1 class="login-title">Subscription Success</h1>
        <input
          @click="goToHome()"
          type="button"
          class="sign-up"
          value="Go to Home"
        />
      </section>
    </main>
  </div>
</template>

<script>
import router from "../router";
import { subscribeUserAction } from "../services/users";
export default {
  data() {
    return {
      idSessionCheckout: null,
    };
  },
  methods: {
    goToHome() {
      router.push({ name: "home" });
    },
    async subscribeUser() {
      const res_data = await subscribeUserAction(this.idSessionCheckout);
      console.log(res_data)
    },
  },
  mounted() {
    if (this.$route.params.sessionId) {
      alert(this.$route.params.sessionId);
      this.idSessionCheckout = this.$route.params.sessionId;
      this.subscribeUser()
    }
  },
};
</script>

<style src="@/assets/styles/views/404.css"></style>